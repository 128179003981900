.chart {
    fill: white;
    font-size: 12px;
    text-align: center;
    position: relative;
    margin: 0 auto;
}

.chart .blip:hover {
    cursor: pointer;
}

.chart .tooltip {
    padding: 10px 10px;
    border-radius: 11px;
}

.chart .ring-label {
    text-transform: uppercase;
    font-size: 8px;
    font-weight: bold;
}