@import "../../styles/sccs-vars.scss";

.page {
  max-width: 1200px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    flex: 0 0 auto;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;

    @media (max-width: $until-sm) {
      margin: 0;
    }

    @media (min-width: $until-sm) {
      .button-flag__logo {
        display: none;
      }
    }

    @media (max-width: $until-sm) {
      .nav {
        display: none;
      }

      .button-flag {
        display: inline-flex;
        height: 25px;
        margin-right: 5px;
      }

      .button-flag:hover {
        opacity: 1;
      }

      .logo-link__text {
        display: none;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &__footer {
    margin-top: 5px;
    flex: 0 0 auto;

    .button-flag {
      display: none;      
    }
  }
}
